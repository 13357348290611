import {
  Button,
  Center,
  Group,
  Image,
  Modal,
  Select,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React, { useReducer, useState } from "react";

import logo from "../../assets/logo.png";
import { url } from "../../(settings)/linkUrl";

interface dataAdd {
  onLoadPage: () => any;
}

export default function AppModelAddMenu({ onLoadPage }: dataAdd) {
  const [opened, { open, close }] = useDisclosure(false);
  const [dataProduct, setdataproduct] = useState([]);

  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const form = useForm({
    initialValues: {
      namemenu: "",
      showtypemenu: "",
    },

    validate: {
      namemenu: isNotEmpty("ระบุชื่อเมนู"),
      showtypemenu: isNotEmpty("ระบุประเภทเมนู"),
    },
  });

  const funcSubmit = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(form.values),
    };
    fetch(`${url}listmenu/listmenu`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        onLoadPage();
        close();
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const LoadProduct = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(`${url}product/product`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setdataproduct(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const openInsertMenu = () => {
    open();
    LoadProduct();
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="เพิ่ม/แก้ไข เมนู"
        transitionProps={{
          transition: "fade",
          duration: 300,
          timingFunction: "linear",
        }}
      >
        <form onSubmit={form.onSubmit(funcSubmit)}>
          <Center>
            <Image src={logo} width={150} height={130}></Image>
          </Center>

          <TextInput
            withAsterisk
            label="ชื่อเมนู"
            mt="md"
            placeholder="ชื่อเมนู"
            {...form.getInputProps("namemenu")}
          />
          <Select
            label="ประเภทเมนูที่จะแสดง"
            placeholder="ประเภทเมนูที่จะแสดง"
            dropdownPosition="bottom"
            withAsterisk
            mt="sm"
            data={[{ value: "1", label: "เพิ่มครุภัณฑ์" },{ value: "2", label: "เพิ่มวัสดุ" }]}
            {...form.getInputProps("showtypemenu")}
          />

          <Group position="right" mt="md">
            <Button type="submit" fullWidth loading={data.loading}>
              บันทึก
            </Button>
          </Group>
        </form>
      </Modal>
      <Group position="right" mt="md" mr="md">
        <Button
          variant="subtle"
          leftIcon={<IconPlus size="1rem" />}
          onClick={openInsertMenu}
        >
          เพิ่มรายการเมนู
        </Button>
      </Group>
    </>
  );
}
