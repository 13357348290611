import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  FileInput,
  Group,
  Image,
  Modal,
  NumberInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAlertCircle,
  IconCircleCheck,
  IconPlus,
  IconQrcode,
  IconTrash,
} from "@tabler/icons-react";
import React, { useReducer, useState } from "react";
import logo from "../../assets/logo.png";

import { notifications } from "@mantine/notifications";
import { url } from "../(settings)/linkUrl";

interface dataremove {
  id: string;
  path: string;
  onLoadPage: () => any;
}

export default function AppModelConfrimRemove({
  id,
  path,
  onLoadPage,
}: dataremove) {
  const [opened, { open, close }] = useDisclosure(false);
  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const funcSubmit = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(`${url}${path}/${id}`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        if (json.success == "1") {
          notifications.show({
            title: "แจ้งเตือน",
            message: "ลบข้อมูลสำเร็จ",
            autoClose: 3000,
            icon: <IconCircleCheck />,
            color: "red",
          });
          close();
          onLoadPage();
        }
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="ยืนยันการลบข้อมูล"
        transitionProps={{
          transition: "fade",
          duration: 300,
          timingFunction: "linear",
        }}
      >
        <Box mx="auto">
          <Text fz="sm">ท่านต้องการลบข้อมูลหรือไม่ ?</Text>

          <Group position="right" mt="md">
            <Button loading={data.loading} onClick={funcSubmit}>
              ยืนยัน
            </Button>
            <Button color="red" onClick={close}>
              ยกเลิก
            </Button>
          </Group>
        </Box>
      </Modal>
      <ActionIcon radius="md" color="red" variant="filled" onClick={open}>
        <IconTrash size="1.125rem" />
      </ActionIcon>
    </>
  );
}
