import { Card, Grid } from "@mantine/core";
import React from "react";
import Chart from "chart.js";
import MyChart from "../../(charts)/MyChartBar";

export default function AdminMainPage() {
  return (
    <>
      <Grid gutter="xs">
        <Grid.Col md={6} lg={3}>
          <Card withBorder radius="10">
            <Card.Section withBorder inheritPadding py="xs">
              {" "}
              <MyChart />
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col md={6} lg={3}>
          <Card withBorder radius="10">
            <Card.Section withBorder inheritPadding py="xs">
              {" "}
              <MyChart />
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col md={6} lg={3}>
          <Card withBorder radius="10">
            <Card.Section withBorder inheritPadding py="xs">
              {" "}
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col md={6} lg={3}>
          <Card withBorder radius="10">
            <Card.Section withBorder inheritPadding py="xs">
              {" "}
              <MyChart />
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
}
