import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  FileInput,
  Flex,
  Grid,
  Group,
  Image,
  Modal,
  NumberInput,
  rem,
  Select,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAlertCircle,
  IconCircleCheck,
  IconEdit,
  IconPlus,
  IconQrcode,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import React, { useReducer, useState } from "react";
import logo from "../../assets/logo.png";

import { notifications } from "@mantine/notifications";
import { url } from "../../(settings)/linkUrl";

interface props {
  id?: string;
  durable_id?: string;
  onLoadPage: () => any;
}

interface File2 {
  name: File;
}

export default function AppModelAdd({ id, durable_id, onLoadPage }: props) {
  const [opened, { open, close }] = useDisclosure(false);
  const [files, setFile] = useState<Array<File2>>([]);

  const [valuefunnel, setValuefunnel] = useState([]);
  const [valueUnit, setValueUnit] = useState([]);
  const [valueFiscal, setValueFiscal] = useState([]);
  const [valueAgency, setValueAgency] = useState([]);
  const [valueDepartment, setValueDepartment] = useState([]);
  const [valueEquipment, setValueEquipment] = useState([]);
  const [valueBudget, setValueBudget] = useState([]);

  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  const form = useForm({
    initialValues: {
      t_durable_id: "",
      t_durable_bddate: "",
      t_durable_bmdate: "",
      t_durable_bydate: "",
      t_durable_iddate: "",
      t_durable_imdate: "",
      t_durable_iydate: "",
      t_durable_code: "",
      t_durable_name: "",
      t_durable_detail: "",
      t_durable_count: "",
      t_durable_unit: "",
      t_durable_money: "",
      t_durable_agency: "",
      t_durable_department: "",
      t_durable_funnel: "",
      t_durable_fiscal: "",
      t_durable_equipment: "",
      t_durable_budget:"",
      t_durable_countyear:""
    },
    validate: {
      t_durable_bddate: isNotEmpty("ระบุวันที่ซื้อ"),
      t_durable_bmdate: isNotEmpty("ระบุเดือนที่ซื้อ"),
      t_durable_bydate: isNotEmpty("ระบุปีที่ซื้อ"),
      t_durable_iddate: isNotEmpty("ระบุวันรับเข้า"),
      t_durable_imdate: isNotEmpty("ระบุเดือนรับเข้า"),
      t_durable_iydate: isNotEmpty("ระบุปีรับเข้า"),
      t_durable_code: isNotEmpty("ระบุหมายเลขครุภัณฑ์"),
      t_durable_name: isNotEmpty("ระบุชื่อครุภัณฑ์"),
      t_durable_detail: isNotEmpty("ระบุคุณลักษณะ"),
      t_durable_count: isNotEmpty("ระบุจำนวน"),
      t_durable_unit: isNotEmpty("ระบุหน่วยนับ"),
      t_durable_money: isNotEmpty("ระบุยอดเงินต่อหน่วย"),
      t_durable_agency: isNotEmpty("ระบุกลุ่มงาน"),
      t_durable_department: isNotEmpty("ระบุแผนก"),
      t_durable_funnel: isNotEmpty("ระบุวิธีการได้มา"),
      t_durable_fiscal: isNotEmpty("ระบุปีงบประมาณ"),
      t_durable_equipment: isNotEmpty("ระบุประเภทครุภัณฑ์"),
      t_durable_budget: isNotEmpty("ระบุประเภทงบปีประมาณ"),
      t_durable_countyear: isNotEmpty("ระบุอายุครุภัณฑ์"),
    },
  });

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const funcSubmit = async () => {
    let frmdata = new FormData();

    frmdata.append("file", files?.[0]?.name);
    frmdata.append("t_durable_id", form.values.t_durable_id);
    frmdata.append("t_durable_bddate", form.values.t_durable_bddate);
    frmdata.append("t_durable_bmdate", form.values.t_durable_bmdate);
    frmdata.append("t_durable_bydate", form.values.t_durable_bydate);
    frmdata.append("t_durable_iddate", form.values.t_durable_iddate);
    frmdata.append("t_durable_imdate", form.values.t_durable_imdate);
    frmdata.append("t_durable_iydate", form.values.t_durable_iydate);
    frmdata.append("t_durable_code", form.values.t_durable_code);
    frmdata.append("t_durable_name", form.values.t_durable_name);
    frmdata.append("t_durable_detail", form.values.t_durable_detail);
    frmdata.append("t_durable_count", form.values.t_durable_count);
    frmdata.append("t_durable_unit", form.values.t_durable_unit);
    frmdata.append("t_durable_money", form.values.t_durable_money);
    frmdata.append("t_durable_agency", form.values.t_durable_agency);
    frmdata.append("t_durable_department", form.values.t_durable_department);
    frmdata.append("t_durable_funnel", form.values.t_durable_funnel);
    frmdata.append("t_durable_fiscal", form.values.t_durable_fiscal);
    frmdata.append("t_durable_equipment", form.values.t_durable_equipment);
    frmdata.append("t_durable_budget", form.values.t_durable_budget);

    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: frmdata,
    };

    await fetch(`${url}durable_articles/durable_articles/${id}`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        if (json.success == "1") {
          notifications.show({
            title: "แจ้งเตือน",
            message: "เพิ่มข้อมูลสำเร็จ",
            autoClose: 3000,
            icon: <IconCircleCheck />,
            color: "green",
          });
          close();
          onLoadPage();
        }
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const getimage = (payload: File) => {
    setFile([
      {
        name: payload,
      },
    ]);
  };

  const loadFunnel = async () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(`${url}funnel/select_funnel`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setValuefunnel(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const loadunit = async () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(`${url}unit/select_unit`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setValueUnit(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const loadbudget = async () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(`${url}budget/select_budget`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setValueBudget(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const loadFiscal = async () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(`${url}fiscal_year/select_fiscal_year`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setValueFiscal(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const loadAgency = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(`${url}agency/select_agency`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setValueDepartment([]);
        setValueAgency(json);
        open();
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const loadDepartment = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(
      `${url}department/select_department/${form.values.t_durable_agency}`,
      requestOptions
    )
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setValueDepartment(json);
        open();
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const loadEquipment = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(
      `${url}equipment/select_equipment/${form.values.t_durable_agency}`,
      requestOptions
    )
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setValueEquipment(json);
        open();
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const loadAdd = async () => {
    open();
    await loadFunnel();
    await loadunit();
    await loadFiscal();
    await loadAgency();
    await loadEquipment();
    await loadbudget();
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="เพิ่มข้อมูล"
        size="xl"
        transitionProps={{
          transition: "fade",
          duration: 300,
          timingFunction: "linear",
        }}
      >
        <Box>
          <form onSubmit={form.onSubmit(funcSubmit)}>
            <Grid>
              <Grid.Col md={6} lg={6}>
                <TextInput
                  withAsterisk
                  mt="sm"
                  label="หมายเลขครุภัณฑ์"
                  placeholder="หมายเลขครุภัณฑ์"
                  {...form.getInputProps("t_durable_code")}
                />
                <TextInput
                  withAsterisk
                  mt="sm"
                  label="ชื่อครุภัณฑ์"
                  placeholder="ชื่อครุภัณฑ์"
                  {...form.getInputProps("t_durable_name")}
                />
                <Textarea
                  placeholder="คุณลักษณะ"
                  label="คุณลักษณะ"
                  withAsterisk
                  mt="sm"
                  minRows={4}
                  maxRows={6}
                  {...form.getInputProps("t_durable_detail")}
                />

                <Select
                  withAsterisk
                  mt="sm"
                  label="ประเภทครุภัณฑ์"
                  placeholder="ประเภทครุภัณฑ์"
                  data={valueEquipment}
                  {...form.getInputProps("t_durable_equipment")}
                />
                <Flex
                  mih={50}
                  gap="md"
                  justify="center"
                  align="center"
                  direction="row"
                  wrap="nowrap"
                >
                  <Select
                    dropdownPosition="bottom"
                    label="วันที่ซื้อ"
                    placeholder="วัน"
                    withAsterisk
                    mt="sm"
                    data={[
                      { value: "01", label: "1" },
                      { value: "02", label: "2" },
                      { value: "03", label: "3" },
                      { value: "04", label: "4" },
                      { value: "05", label: "5" },
                      { value: "06", label: "6" },
                      { value: "07", label: "7" },
                      { value: "08", label: "8" },
                      { value: "09", label: "9" },
                      { value: "10", label: "10" },
                      { value: "11", label: "11" },
                      { value: "12", label: "12" },
                      { value: "13", label: "13" },
                      { value: "14", label: "14" },
                      { value: "15", label: "15" },
                      { value: "16", label: "16" },
                      { value: "17", label: "17" },
                      { value: "18", label: "18" },
                      { value: "19", label: "19" },
                      { value: "20", label: "20" },
                      { value: "21", label: "21" },
                      { value: "22", label: "22" },
                      { value: "23", label: "23" },
                      { value: "24", label: "24" },
                      { value: "25", label: "25" },
                      { value: "26", label: "26" },
                      { value: "27", label: "27" },
                      { value: "28", label: "28" },
                      { value: "29", label: "29" },
                      { value: "30", label: "30" },
                      { value: "31", label: "31" },
                    ]}
                    {...form.getInputProps("t_durable_bddate")}
                  />
                  <Select
                    dropdownPosition="bottom"
                    label="เดือนที่ซื้อ"
                    placeholder="เดือน"
                    withAsterisk
                    width={200}
                    mt="sm"
                    data={[
                      { value: "01", label: "มกราคม" },
                      { value: "02", label: "กุมภาพันธ์" },
                      { value: "03", label: "มีนาคม" },
                      { value: "04", label: "เมษายน" },
                      { value: "05", label: "พฤษภาคม" },
                      { value: "06", label: "มิถุนายน" },
                      { value: "07", label: "กรกฎาคม" },
                      { value: "08", label: "สิงหาคม" },
                      { value: "09", label: "กันยายน" },
                      { value: "10", label: "ตุลาคม" },
                      { value: "11", label: "พฤศจิกายน" },
                      { value: "12", label: "ธันวาคม" },
                    ]}
                    {...form.getInputProps("t_durable_bmdate")}
                  />
                  <Select
                    dropdownPosition="bottom"
                    label="ปีที่ซื้อ"
                    placeholder="ปี"
                    withAsterisk
                    mt="sm"
                    data={[
                      { value: "2566", label: "2566" },
                      { value: "2565", label: "2565" },
                      { value: "2564", label: "2564" },
                    ]}
                    {...form.getInputProps("t_durable_bydate")}
                  />
                </Flex>
                <Select
                  withAsterisk
                  mt="sm"
                  label="ประเภทงบประมาณ"
                  placeholder="ประเภทงบประมาณ"
                  data={valueBudget}
                  {...form.getInputProps("t_durable_budget")}
                />
                <Select
                  withAsterisk
                  mt="sm"
                  label="อายุครุภัณฑ์"
                  placeholder="อายุครุภัณฑ์"
                  data={['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15']}
                  {...form.getInputProps("t_durable_countyear")}
                />
                
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Flex
                  mih={50}
                  gap="md"
                  justify="center"
                  align="center"
                  direction="row"
                  wrap="nowrap"
                >
                  <Select
                    dropdownPosition="bottom"
                    label="วันที่รับ"
                    placeholder="วัน"
                    withAsterisk
                    mt="sm"
                    data={[
                      { value: "01", label: "1" },
                      { value: "02", label: "2" },
                      { value: "03", label: "3" },
                      { value: "04", label: "4" },
                      { value: "05", label: "5" },
                      { value: "06", label: "6" },
                      { value: "07", label: "7" },
                      { value: "08", label: "8" },
                      { value: "09", label: "9" },
                      { value: "10", label: "10" },
                      { value: "11", label: "11" },
                      { value: "12", label: "12" },
                      { value: "13", label: "13" },
                      { value: "14", label: "14" },
                      { value: "15", label: "15" },
                      { value: "16", label: "16" },
                      { value: "17", label: "17" },
                      { value: "18", label: "18" },
                      { value: "19", label: "19" },
                      { value: "20", label: "20" },
                      { value: "21", label: "21" },
                      { value: "22", label: "22" },
                      { value: "23", label: "23" },
                      { value: "24", label: "24" },
                      { value: "25", label: "25" },
                      { value: "26", label: "26" },
                      { value: "27", label: "27" },
                      { value: "28", label: "28" },
                      { value: "29", label: "29" },
                      { value: "30", label: "30" },
                      { value: "31", label: "31" },
                    ]}
                    {...form.getInputProps("t_durable_iddate")}
                  />
                  <Select
                    dropdownPosition="bottom"
                    label="เดือนที่รับ"
                    placeholder="เดือน"
                    withAsterisk
                    width={200}
                    mt="sm"
                    data={[
                      { value: "01", label: "มกราคม" },
                      { value: "02", label: "กุมภาพันธ์" },
                      { value: "03", label: "มีนาคม" },
                      { value: "04", label: "เมษายน" },
                      { value: "05", label: "พฤษภาคม" },
                      { value: "06", label: "มิถุนายน" },
                      { value: "07", label: "กรกฎาคม" },
                      { value: "08", label: "สิงหาคม" },
                      { value: "09", label: "กันยายน" },
                      { value: "10", label: "ตุลาคม" },
                      { value: "11", label: "พฤศจิกายน" },
                      { value: "12", label: "ธันวาคม" },
                    ]}
                    {...form.getInputProps("t_durable_imdate")}
                  />
                  <Select
                    dropdownPosition="bottom"
                    label="ปีที่รับ"
                    placeholder="ปี"
                    withAsterisk
                    mt="sm"
                    data={[
                      { value: "2566", label: "2566" },
                      { value: "2565", label: "2565" },
                      { value: "2564", label: "2564" },
                    ]}
                    {...form.getInputProps("t_durable_iydate")}
                  />
                </Flex>
                <Select
                  dropdownPosition="bottom"
                  label="ปีงบประมาณ"
                  placeholder="ปีงบประมาณ"
                  withAsterisk
                  mt="sm"
                  data={valueFiscal}
                  {...form.getInputProps("t_durable_fiscal")}
                />
                <NumberInput
                  withAsterisk
                  label="จำนวน"
                  mt="md"
                  placeholder="จำนวน"
                  precision={2}
                  min={0}
                  step={0.05}
                  max={100000000}
                  {...form.getInputProps("t_durable_count")}
                />
                <Select
                  dropdownPosition="bottom"
                  label="หน่วยนับ"
                  placeholder="หน่วยนับ"
                  withAsterisk
                  mt="sm"
                  data={valueUnit}
                  {...form.getInputProps("t_durable_unit")}
                />
                <Select
                  dropdownPosition="bottom"
                  label="วิธีการได้มา"
                  placeholder="วิธีการได้มา"
                  withAsterisk
                  mt="sm"
                  data={valuefunnel}
                  {...form.getInputProps("t_durable_funnel")}
                />
                <NumberInput
                  withAsterisk
                  label="ราคาต่อหน่วย"
                  mt="md"
                  placeholder="ราคาต่อหน่วย"
                  precision={2}
                  min={0}
                  step={0.05}
                  max={100000000}
                  {...form.getInputProps("t_durable_money")}
                />
                <FileInput
                  label="ภาพถ่ายครุภัณฑ์"
                  placeholder="ภาพถ่ายครุภัณฑ์"
                  accept="image/png,image/jpeg"
                  withAsterisk
                  icon={<IconUpload size={rem(14)} />}
                  onChange={(payload: File) => getimage(payload)}
                />
                <Select
                  dropdownPosition="bottom"
                  label="กลุ่มงาน"
                  placeholder="กลุ่มงาน"
                  withAsterisk
                  mt="sm"
                  data={valueAgency}
                  {...form.getInputProps("t_durable_agency")}
                />
                <Select
                  dropdownPosition="bottom"
                  label="แผนกงาน"
                  placeholder="แผนกงาน"
                  onDropdownOpen={loadDepartment}
                  withAsterisk
                  mt="sm"
                  data={valueDepartment}
                  {...form.getInputProps("t_durable_department")}
                />
              </Grid.Col>
            </Grid>
            <Group position="left" mt="md">
              <Button type="submit" loading={data.loading}>
                บันทึกข้อมูล
              </Button>
              <Button type="submit" color="red" loading={data.loading}>
                บันทึกข้อมูล
              </Button>
            </Group>
          </form>
        </Box>
      </Modal>

      {durable_id !== "add" ? (
        <ActionIcon radius="md" color="blur" variant="filled" onClick={loadAdd}>
          <IconEdit size="1.125rem" />
        </ActionIcon>
      ) : (
        <Button
          onClick={loadAdd}
          variant="gradient"
          gradient={{ from: "teal", to: "lime", deg: 105 }}
          leftIcon={<IconPlus />}
        >
          เพิ่มข้อมูล
        </Button>
      )}
    </>
  );
}
