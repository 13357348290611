import {
  ActionIcon,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Table,
} from "@mantine/core";
import { IconEditCircle, IconPlus, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useReducer, useState } from "react";
import AppModelConfrimRemove from "../../(models)/AppModelConfrimRemove";
import AppModelAdd from "../components/AppModelAdd";
import AppSearch from "../../(components)/AppSearch";
import { url } from "../../(settings)/linkUrl";
import AppModelFiscalYear from "../components/AppModelFiscalYear";
import AppModelAgency from "../components/AppModelAgency";

export default function AdminSettingAgencyPage() {
  const [dataList, setDataList] = useState([]);
  const [state, setstate] = useState([]);

  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const LoadPage = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(`${url}agency/agency`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setstate(json);
        setDataList(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  useEffect(() => {
    LoadPage();
  }, []);

  const logMessage = () => {
    LoadPage();
  };

  const rows = state.map((element: any, index) => (
    <tr key={element.agriculture_drone_id}>
      <td style={{ textAlign: "center" }} width={50}>
        {index + 1}
      </td>
      <td>{element.agency_name}</td>
      <td style={{ textAlign: "center" }} width={200}>
        <Group spacing="xs" position="center">
          <AppModelAgency
            agency_id={element.agency_id}
            agency_name={element.agency_name}
            onLoadPage={logMessage}
          />
          <AppModelConfrimRemove
            id={element.agency_id}
            path="agency/agency"
            onLoadPage={logMessage}
          />
        </Group>
      </td>
    </tr>
  ));

  return (
    <>
      <LoadingOverlay visible={data.loading} />
      <Box>
        <Group position="right">
          <AppSearch dataFilter={dataList} returnData={setstate} />
          <AppModelAgency
            agency_id="add"
            agency_name=""
            onLoadPage={logMessage}
          />
        </Group>
      </Box>
      <Table mt="md" fontSize="xs" highlightOnHover withBorder>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>#</th>
            <th style={{ textAlign: "center" }}>แผนกงาน</th>
            <th style={{ textAlign: "center" }}>จัดการ</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  );
}
