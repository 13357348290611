import React from 'react'
import { Image, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { url } from '../../(settings)/linkUrl';

interface dataPrope {
    path_image:string;
}


export default function AppModelShowImage({path_image}:dataPrope) {
    const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
    <Modal opened={opened}
        onClose={close}
        title="ตัวอย่างภาพ"
        transitionProps={{
          transition: "fade",
          duration: 300,
          timingFunction: "linear",
        }}>
        <Image src={`${url}/${path_image}`} height="350"  />
    </Modal>
    <Image src={`${url}/${path_image}`} height="50" width="50" style={{margin:'0 auto', cursor:'pointer'}} onClick={open}/>
    </>
    
  )
}
