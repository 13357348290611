import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import NotFoundPage from "./(auth)/not-fount-page/NotFoundPage";
import AdministratorLayout from "./(layouts)/AdministratorLayout";
import AdminMainPage from "./(admisistrators)/admin-main/AdminMainPage";
import RootLayout from "./(layouts)/RootLayout";
import AdminEquipmentPage from "./(admisistrators)/admin-setting/AdminEquipmentPage";
import AdminSettingOfficerPage from "./(admisistrators)/admin-setting/AdminSettingOfficerPage";
import AdminSettingDepartMentPage from "./(admisistrators)/admin-setting/AdminSettingDepartMentPage";
import AdminSettingMenageCustomerPage from "./(admisistrators)/admin-setting/AdminSettingMenageCustomerPage";

import AdminsettingFiscalYearPage from "./(admisistrators)/admin-setting/AdminsettingFiscalYearPage";
import LoginPage from "./(auth)/login-page/LoginPage";
import AdminsettingUnitPage from "./(admisistrators)/admin-setting/AdminsettingUnitPage";
import AdminSettingAgencyPage from "./(admisistrators)/admin-setting/AdminSettingAgencyPage";
import AdminAddPage from "./(admisistrators)/admin-add/AdminAddPage";
import AdminsettingBudgetPage from "./(admisistrators)/admin-setting/AdminsettingBudgetPage";
import AdminsettingFunnel from "./(admisistrators)/admin-setting/AdminsettingFunnel";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<LoginPage />}></Route>
        </Route>
        <Route path="/administrator" element={<AdministratorLayout />}>
          <Route index element={<AdminMainPage />}></Route>
          <Route
            path="/administrator/setting-type-of-equipment"
            element={<AdminEquipmentPage />}
          ></Route>
          <Route
            path="/administrator/setting-department"
            element={<AdminSettingDepartMentPage />}
          ></Route>
          <Route
            path="/administrator/setting-menage-customer"
            element={<AdminSettingMenageCustomerPage />}
          ></Route>
          <Route
            path="/administrator/add/:id"
            element={<AdminAddPage />}
          ></Route>

          <Route
            path="/administrator/setting-type-of-equipment"
            element={<AdminSettingDepartMentPage />}
          ></Route>
          <Route
            path="/administrator/setting-agency"
            element={<AdminSettingAgencyPage />}
          ></Route>
          <Route
            path="/administrator/setting-department"
            element={<AdminSettingMenageCustomerPage />}
          ></Route>
          <Route
            path="/administrator/setting-menage-customer"
            element={<AdminSettingMenageCustomerPage />}
          ></Route>

          <Route
            path="/administrator/setting-fiscal-year"
            element={<AdminsettingFiscalYearPage />}
          ></Route>
          <Route
            path="/administrator/setting-unit"
            element={<AdminsettingUnitPage />}
          ></Route>
          <Route
            path="/administrator/setting-budget"
            element={<AdminsettingBudgetPage />}
          ></Route>

          <Route
            path="/administrator/setting-funnel"
            element={<AdminsettingFunnel />}
          ></Route>

          <Route path="*" element={<NotFoundPage />}></Route>
        </Route>
        <Route path="*" element={<NotFoundPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
