import {
  ActionIcon,
  Box,
  Button,
  Group,
  Modal,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconCircleCheck, IconEditCircle, IconPlus } from "@tabler/icons-react";
import React, { useReducer, useState } from "react";
import { notifications } from "@mantine/notifications";
import { url } from "../../(settings)/linkUrl";

interface dataCustomer {
  agency_id: string;
  agency_name: string;
  onLoadPage: () => any;
}

export default function AppModelAgency({
  agency_id,
  agency_name,
  onLoadPage,
}: dataCustomer) {
  const [opened, { open, close }] = useDisclosure(false);
  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const form = useForm({
    initialValues: {
      agency_id: agency_id,
      agency_name: agency_name,
    },
    validate: {
      agency_name: isNotEmpty("ระบุแผนก"),
    },
  });

  const funcSubmit = async () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    if (agency_id === "add") {
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(form.values),
      };
      await fetch(`${url}agency/agency`, requestOptions)
        .then((respone) => {
          if (!respone.ok) throw Error(respone.statusText);
          return respone.json();
        })
        .then((json) => {
          dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
          if (json.success == "1") {
            notifications.show({
              title: "แจ้งเตือน",
              message: "บันทึกข้อมูลสำเร็จ",
              autoClose: 3000,
              icon: <IconCircleCheck />,
              color: "green",
            });
            onLoadPage();
            close();
          }
        })
        .catch((error) => {
          dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
        });
    } else {
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(form.values),
      };
      fetch(`${url}agency/agency`, requestOptions)
        .then((respone) => {
          if (!respone.ok) throw Error(respone.statusText);
          return respone.json();
        })
        .then((json) => {
          dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
          if (json.success == "1") {
            notifications.show({
              title: "แจ้งเตือน",
              message: "อัพเดตข้อมูลสำเร็จ",
              autoClose: 3000,
              icon: <IconCircleCheck />,
              color: "yellow",
            });
            close();
            onLoadPage();
          }
        })
        .catch((error) => {
          dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
        });
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="แผนก"
        transitionProps={{
          transition: "fade",
          duration: 300,
          timingFunction: "linear",
        }}
      >
        <Box mx="auto">
          <form onSubmit={form.onSubmit(funcSubmit)}>
            <div>
              <TextInput
                withAsterisk
                mt="sm"
                label="แผนก"
                placeholder="แผนก"
                {...form.getInputProps("agency_name")}
              />

              <Group position="right" mt="md">
                <Button type="submit" fullWidth loading={data.loading}>
                  บันทึกข้อมูล
                </Button>
              </Group>
            </div>
          </form>
        </Box>
      </Modal>
      {agency_id !== "add" ? (
        <ActionIcon onClick={open} radius="md" color="green" variant="filled">
          <IconEditCircle size="1.125rem" />
        </ActionIcon>
      ) : (
        <Button
          onClick={open}
          variant="gradient"
          gradient={{ from: "teal", to: "lime", deg: 105 }}
          leftIcon={<IconPlus />}
        >
          เพิ่มข้อมูล
        </Button>
      )}
    </>
  );
}
