import {
  ActionIcon,
  Button,
  Group,
  List,
  Modal,
  ThemeIcon,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconArrowAutofitLeft,
  IconEditCircle,
  IconPlus,
  IconTrashFilled,
} from "@tabler/icons-react";
import { useReducer, useState } from "react";

import AppModelAddMenu from "./AppModelAddMenu";
import AppModelAddSubMenu from "./AppModelAddSubMenu";
import AppModelConfrimRemove from "./AppModelConfrimRemove";
import { url } from "../../(settings)/linkUrl";

interface dataAdd {
  onLoadPage: () => any;
}

export default function AppModelAddListMenu({ onLoadPage }: dataAdd) {
  const [opened, { open, close }] = useDisclosure(false);
  const [data_list_add, setdata_list_add] = useState([]);

  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const loadlistmenu = async () => {
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(`${url}/listmenu/listmenu`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setdata_list_add(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const LoadPage = () => {
    loadlistmenu();
  };

  const openListmenu = () => {
    loadlistmenu();
    open();
  };

  const closeModel = () => {
    onLoadPage();
    close();
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={closeModel}
        size="lg"
        title="จัดการรายการเมนู"
        transitionProps={{
          transition: "fade",
          duration: 300,
          timingFunction: "linear",
        }}
      >
        <AppModelAddMenu onLoadPage={LoadPage} />
        {data_list_add.map((list: any) => (
          <>
            {list.link === "-" ? (
              <div >
                <div
                 
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: "2px",
                  }}
                >
                  {list.label}
                  {list.type_create != "a" ? (
                    <Group>
                      <AppModelAddSubMenu
                        id={list.id}
                        product_id={list.product_id}
                        onLoadPage={LoadPage}
                      />
                      <ActionIcon onClick={open} color="green" variant="filled">
                        <IconEditCircle size="1rem" />
                      </ActionIcon>
                      <AppModelConfrimRemove
                        onLoadPage={LoadPage}
                        id={list.id}
                        path="listmenu/listmenu"
                      />
                    </Group>
                  ) : (
                    <></>
                  )}
                </div>

                {list.links.map((list_sub: any) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      margin: "2px",
                      paddingLeft: "25px",
                    }}
                  >
                    {list_sub.label}
                    {list.type_create != "a" ? (
                      <Group>
                        <ActionIcon
                          onClick={open}
                          color="green"
                          variant="filled"
                        >
                          <IconEditCircle size="1rem" />
                        </ActionIcon>
                        <AppModelConfrimRemove
                          onLoadPage={LoadPage}
                          id={list_sub.id}
                          path={`listmenu/sublistmenu/${list.id}`}
                        />
                      </Group>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  margin: "2px",
                }}
              >
                {list.label}
                {list.type_create != "a" ? (
                  <Group>
                    <AppModelAddSubMenu
                      id={list.id}
                      product_id={list.product_id}
                      onLoadPage={LoadPage}
                    />
                    <ActionIcon onClick={open} color="green" variant="filled">
                      <IconEditCircle size="1rem" />
                    </ActionIcon>
                    <AppModelConfrimRemove
                      onLoadPage={LoadPage}
                      id={list.id}
                      path="listmenu/listmenu"
                    />
                  </Group>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        ))}
      </Modal>
      <Group position="right" mt="md" mr="md">
        <Button
          variant="subtle"
          leftIcon={<IconPlus size="1rem" />}
          onClick={openListmenu}
        >
          จัดการเมนู
        </Button>
      </Group>
    </>
  );
}
