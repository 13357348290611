import { Icon2fa, IconHome, IconList, IconUser } from "@tabler/icons-react";
import React from "react";

interface UserIcon {
  icon?: string;
}

export default function AppUseIcon({ icon }: UserIcon) {
  const useIconList = () => {
    switch (icon) {
      case "Home":
        return <IconHome />;
      default:
        return <IconList />;
    }
  };

  return <>{useIconList()}</>;
}
