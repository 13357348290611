import { useEffect, useReducer, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Group,
  ScrollArea,
  rem,
  createStyles,
  Drawer,
  Image,
  Divider,
} from "@mantine/core";

import logo from "../assets/logo.png";

import { Outlet, useNavigate } from "react-router-dom";

import { useDisclosure } from "@mantine/hooks";

import { IconAlertCircle } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { url } from "../(settings)/linkUrl";
import { LinksGroup } from "../(components)/NavbarLinksGroup";
import UserProfile from "../(components)/UserProfile";
import AppModelAddListMenu from "../(admisistrators)/components/AppModelAddListMenu";
import MenuUser from "../(components)/MenuUser";

interface LinksGroupProps {
  icon: string;
  label: string;
  initiallyOpened: boolean;
  link: string;
  links: { label: string; link: string }[];
}

const initialmockdata: LinksGroupProps[] = [];

const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 0,
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  section: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme == "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
    },
  },

  searchCode: {
    fontWeight: 700,
    fontSize: rem(10),
    backgroundColor:
      theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
    border: `${rem(1)} solid ${
      theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.md,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme == "dark" ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme == "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  mainLink: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.md,
    padding: `${rem(8)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 400,
    color:
      theme.colorScheme == "dark" ? theme.colors.dark[0] : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme == "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme == "dark" ? theme.white : theme.black,
    },
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color:
      theme.colorScheme == "dark" ? theme.colors.dark[2] : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: rem(20),
    height: rem(20),
    pointerEvents: "none",
  },

  collections: {
    paddingLeft: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingRight: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: `calc(${theme.spacing.md} + ${rem(2)})`,
    paddingRight: theme.spacing.md,
    marginBottom: rem(5),
  },

  collectionLink: {
    display: "block",
    padding: `${rem(8)} ${theme.spacing.xs}`,
    textDecoration: "none",
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.xs,
    color:
      theme.colorScheme == "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    lineHeight: 1,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme == "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme == "dark" ? theme.white : theme.black,
    },
  },
}));

function AdministratorLayout() {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [mockdata, setmockdata] = useState(initialmockdata);

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const loadlistmenu = async () => {
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(`${url}/listmenu/listmenu`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setmockdata(json);
        console.log(mockdata);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  const mainLinks = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} onOpenmenu={closeDrawer} />
  ));

  const nav = useNavigate();
  const [checkLoad, setCheckLoad] = useState(false);
  const signOut = () => {
    notifications.show({
      title: "แจ้งเตือน",
      message: "ไม่มีสิทธิ์เข้าใช้งานในส่วนนี้",
      autoClose: 3000,
      icon: <IconAlertCircle />,
      color: "red",
    });
    nav(`/`);
    window.localStorage.clear();
  };

  const checkData = () => {
    setCheckLoad(true);
  };

  useEffect(() => {
    checkData();
    if (checkLoad) {
      if (localStorage.getItem("type") !== "1") {
        signOut();
      }
    }
  }, [checkLoad]);

  useEffect(() => {
    loadlistmenu();
  }, []);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme == "dark"
              ? theme.colors.dark[8]
              : theme.colors.white,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar width={{ sm: 300 }} p="md" className={classes.navbar}>
          <Navbar.Section className={classes.header}>
            <UserProfile
              image_user="https://i.imgur.com/fGxgcDF.png"
              name_user="คมกริช กลิ่นอาจ"
              type_user="ผู้ดูแลระบบ"
              //   icon={<IconSelector size="0.9rem" stroke={1.5} />}
            />
          </Navbar.Section>

          <Navbar.Section
            grow
            className={classes.section}
            component={ScrollArea}
          >
            <AppModelAddListMenu onLoadPage={loadlistmenu} />
            <div className={classes.mainLinks}>{mainLinks}</div>
          </Navbar.Section>

          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            title=""
            className={classes.hiddenDesktop}
            zIndex={1000000}
          >
            <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
              <div style={{ marginLeft: "10px" }}>
                <UserProfile
                  image_user="https://i.imgur.com/fGxgcDF.png"
                  name_user="คมกริช กลิ่นอาจ"
                  type_user="ผู้ดูแลระบบ"
                  //   icon={<IconSelector size="0.9rem" stroke={1.5} />}
                />
              </div>

              <Divider
                my="sm"
                color={theme.colorScheme == "dark" ? "dark.5" : "gray.1"}
              />
              <AppModelAddListMenu onLoadPage={loadlistmenu} />
              <div className={classes.mainLinks}>{mainLinks}</div>
            </ScrollArea>
          </Drawer>
        </Navbar>
      }
      header={
        <Header height={{ base: 60, md: 70 }} p="md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Navbar.Section grow>
              <Group position="apart">
                <Image src={logo} width={160} height={60}></Image>
                <MenuUser
                  image="https://i.imgur.com/fGxgcDF.png"
                  name="คมกริช กลิ่นอาจ"
                  type="ผู้ดูแลระบบ"
                />
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={toggleDrawer}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>
              </Group>
            </Navbar.Section>
          </div>
        </Header>
      }
    >
      <Outlet />
    </AppShell>
  );
}

export default AdministratorLayout;
