import {
  Box,
  Button,
  Center,
  Grid,
  Group,
  Image,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useReducer } from "react";
import { url } from "../../(settings)/linkUrl";
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react";
import logo from "../../assets/logo.png";
import { isNotEmpty, useForm } from "@mantine/form";
import "./LoginPage.css";

export default function LoginPage() {
  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      username: isNotEmpty("ระบุชื่อผู้ใช้"),
      password: (value) => (value.length < 2 ? "ระบุรหัสผ่านผู้ใช้" : null),
    },
  });

  const funcSubmit = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(form.values),
    };
    fetch(`${url}user/login`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        if (json.success === "1") {
          localStorage.setItem("auth", json.auth);
          localStorage.setItem("name_th", json.name_th);
          localStorage.setItem("lname_th", json.lname_th);
          localStorage.setItem("type", json.type);
          notifications.show({
            title: "เข้าระบบสำเร็จ",
            message: "กรุณารอสักครู่ กำลังเข้าหน้าผู้ใช้งาน",
            autoClose: 3000,
            icon: <IconCircleCheck />,
            color: "green",
          });
          if (json.type === "1") {
            window.location.href = "./administrator";
          } else if (json.type === "2") {
            window.location.href = "./dashboard";
          } else if (json.type === "3") {
            window.location.href = "./agent";
          }
        } else if (json.success === "0") {
          notifications.show({
            title: "แจ้งเตือน",
            message:
              "ไม่สามารถเข้าระบบได้ กรุณาตรวจสอบข้อมูลผู้ใช้ใหม่อีกครั้ง",
            autoClose: 3000,
            color: "red",
            icon: <IconAlertCircle />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  return (
    <div className="LoginPage">
      <Box maw={300} mx="auto" className="LoginBox">
        <form onSubmit={form.onSubmit(funcSubmit)}>
          <Center>
            <Image src={logo} width={250}></Image>
          </Center>

          <TextInput
            withAsterisk
            label="รหัสผู้ใช้"
            mt="md"
            placeholder="รหัสผู้ใช้"
            {...form.getInputProps("username")}
          />
          <PasswordInput
            withAsterisk
            label="รหัสผ่านผู้ใช้"
            mt="md"
            placeholder="รหัสผ่านผู้ใช้"
            {...form.getInputProps("password")}
          />
          <Group position="right" mt="md">
            <Button type="submit" fullWidth loading={data.loading}>
              เข้าสู่ระบบ
            </Button>
          </Group>
        </form>
      </Box>
    </div>
  );
}
