import { Image, Title } from "@mantine/core";
import "./UserProfile.css";

interface UserProfileDetail {
  image_user: string;
  name_user: string;
  type_user: string;
}

function UserProfile({ image_user, name_user, type_user}:UserProfileDetail) {
  return (
    <div className="UserProfile">
      <Image src={image_user} width={50} height={50}></Image>
      <div className="UserProfile-Detail">
        <Title size={14}>{name_user}</Title>
        <Title size={12}>{type_user}</Title>
      </div>
    </div>
  );
}

export default UserProfile;

