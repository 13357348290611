import {
  ActionIcon,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Table,
} from "@mantine/core";
import { IconEditCircle, IconPlus, IconTrash } from "@tabler/icons-react";

import React, { useEffect, useReducer, useState } from "react";
import AppModelConfrimRemove from "../../(models)/AppModelConfrimRemove";
import AppModelAdd from "../components/AppModelAdd";

export default function AdminSettingMenageCustomerPage() {
  const [articles, setArticles] = useState([
    {
      t_date: "25/11/2552",
      t_durable: "KSP52-040001-00001",
      t_durable_name: "เครื่องปรับอากาศ",
      t_durable_count: "เครื่อง",
      t_durable_unit: "1",
      t_durable_money: "28000",
      t_durable_all_money: "28000",
      t_durable_budget: "3 งบประมาณแผ่นดิน",
      t_durable_type: "04.0001",
      t_durable_agency: "ห้องการเงิน",
      t_durable_status: "เสื่อมคุณภาพ",
    },
  ]);
  const [state, setstate] = useState({
    query: "",
    list: articles,
  });

  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const LoadPage = () => {};
  useEffect(() => {
    LoadPage();
  }, []);

  const logMessage = () => {
    LoadPage();
  };

  const rows = state.list.map((element: any, index) => (
    <tr key={element.agriculture_drone_id}>
      <td style={{ textAlign: "center" }} width={50}>
        {index + 1}
      </td>
      <td>{element.t_date}</td>
      <td>{element.t_durable}</td>
      <td>{element.t_durable_name}</td>
      <td>{element.t_durable_count}</td>
      <td>{element.t_durable_unit}</td>
      <td>{element.t_durable_money}</td>
      <td>{element.t_durable_all_money}</td>
      <td>{element.t_durable_budget}</td>
      <td>{element.t_durable_type}</td>
      <td>{element.t_durable_agency}</td>
      <td>{element.t_durable_status}</td>
      <td style={{ textAlign: "center" }} width={200}>
        <Group spacing="xs" position="center">
          {/* <AppModelAdd
            durable_id={element.t_durable_id}
            path="dff"
            onLoadPage={logMessage}
          /> */}
          <AppModelConfrimRemove
            id={element.t_durable_id}
            path="dff"
            onLoadPage={logMessage}
          />
        </Group>
      </td>
    </tr>
  ));

  return (
    <>
      <LoadingOverlay visible={data.loading} />
      <Box>
        <Group position="right">
          {/* <AppModelAdd durable_id="add" path="dff" onLoadPage={logMessage} /> */}
        </Group>
      </Box>
      <Table mt="md" fontSize="xs" highlightOnHover withBorder>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>#</th>
            <th style={{ textAlign: "center" }}>วัน เดือน ปี</th>
            <th style={{ textAlign: "center" }}>หมายเลขครุภัณฑ์</th>
            <th style={{ textAlign: "center" }}>ชื่อครุภัณฑ์</th>
            <th style={{ textAlign: "center" }}>จำนวน</th>
            <th style={{ textAlign: "center" }}>หน่วยนับ</th>
            <th style={{ textAlign: "center" }}>ราคาต่อหน่วย</th>
            <th style={{ textAlign: "center" }}>ราคารวม</th>
            <th style={{ textAlign: "center" }}>งบประมาณ</th>
            <th style={{ textAlign: "center" }}>ประเภทครุภัณฑ์</th>
            <th style={{ textAlign: "center" }}>หน่วยงาน</th>
            <th style={{ textAlign: "center" }}>สถานะ</th>
            <th style={{ textAlign: "center" }}>จัดการ</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  );
}
