import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { IconAlertCircle } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

export default function RootLayout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
