import {
  Badge,
  Box,
  Center,
  createStyles,
  Group,
  LoadingOverlay,
  Pagination,
  rem,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import React, { useEffect, useReducer, useState } from "react";
import AppModelConfrimRemove from "../../(models)/AppModelConfrimRemove";
import AppModelAdd from "../components/AppModelAdd";
import { useParams } from "react-router-dom";
import AppSearch from "../../(components)/AppSearch";
import { url } from "../../(settings)/linkUrl";
import AppModelShowImage from "../components/AppModelShowImage";
import { keys } from "@mantine/utils";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
}));

interface RowData {
  agriculture_drone_id: string;
  t_durable_auto: string;
  t_durable_id: string;
  t_durable_code: string;
  t_durable_name: string;
  t_durable_idate: string;
  t_durable_bdate: string;
  t_durable_detail: string;
  t_durable_count: string;
  t_durable_unit: string;
  t_durable_money: string;
  t_durable_all_money: string;
  t_durable_budget: string;
  t_durable_type: string;
  t_durable_agency: string;
  t_durable_department: string;
  t_durable_funnel: string;
  t_durable_fiscal: string;
  t_durable_equipment: string;
  t_durable_status: string;
  t_durable_image: string;
  t_durable_countyear: string;
  equipment_name: string;
  agency_name: string;
  budget_name: string;
  department_name: string;
  unit_name: string;
  t_durable_status_use: string;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

export default function AdminAddPage() {
  let { id } = useParams();

  useEffect(() => {
    LoadPage();
  }, [id]);

  const [search, setSearch] = useState("");

  const [dataList, setDataList] = useState<RowData[]>([]);
  const [sortedData, setSortedData] = useState<RowData[]>([]);

  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const initialState = {
    loading: "",
    error: "",
    data: [],
  };

  function apiReducer(state: any, action: any) {
    switch (action.type) {
      case "DATA_FETCH_START":
        return { ...state, loading: "yes" };
      case "DATA_FETCH_SUCCESS":
        return { ...state, loading: "", data: action.payload };
      case "DATA_FETCH_FAILURE":
        return { ...state, loading: "", error: action.payload };
      default:
        return state;
    }
  }

  function sortData(
    data: RowData[],
    payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
  ) {
    const { sortBy } = payload;
    if (!sortBy) {
      return filterData(data, payload.search);
    }

    return filterData(
      [...data].sort((a, b) => {
        if (payload.reversed) {
          return b[sortBy].localeCompare(a[sortBy]);
        }
        return a[sortBy].localeCompare(b[sortBy]);
      }),
      payload.search
    );
  }

  function filterData(data: RowData[], search: string) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
      keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
  }

  function Th({ children, reversed, sorted, onSort }: ThProps) {
    const { classes } = useStyles();
    const Icon = sorted
      ? reversed
        ? IconChevronUp
        : IconChevronDown
      : IconSelector;
    return (
      <th className={classes.th}>
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="apart">
            <Text fw={500} fz="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size="0.9rem" stroke={1.5} />
            </Center>
          </Group>
        </UnstyledButton>
      </th>
    );
  }

  const [data, dispatch] = useReducer(apiReducer, initialState);

  const LoadPage = () => {
    dispatch({ type: "DATA_FETCH_START" });
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(`${url}durable_articles/durable_articles/${id}`, requestOptions)
      .then((respone) => {
        if (!respone.ok) throw Error(respone.statusText);
        return respone.json();
      })
      .then((json) => {
        dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        setSortedData(json);
        setDataList(json);
      })
      .catch((error) => {
        dispatch({ type: "DATA_FETCH_FAILURE", payload: error.message });
      });
  };

  useEffect(() => {
    LoadPage();
  }, []);

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(sortedData, { sortBy: field, reversed, search }));
  };

  const logMessage = () => {
    LoadPage();
  };

  const loadStatus = (item: string) => {
    switch (item) {
      case "0":
        return (
          <Badge variant="gradient" gradient={{ from: "orange", to: "red" }}>
            จำหน่าย
          </Badge>
        );
      case "1":
        return (
          <Badge
            variant="gradient"
            gradient={{ from: "teal", to: "lime", deg: 105 }}
          >
            ใช้งานปกติ
          </Badge>
        );
      case "2":
        return (
          <Badge
            variant="gradient"
            gradient={{ from: "teal", to: "blue", deg: 60 }}
          >
            ชำรุด
          </Badge>
        );
      case "3":
        return (
          <Badge variant="gradient" gradient={{ from: "yellow", to: "orange" }}>
            ซ่อม
          </Badge>
        );
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(dataList, {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5); // จำนวนผู้ใช้งานต่อหน้า
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = sortedData.slice(indexOfFirstUser, indexOfLastUser);
  // const pagesToShow = 10;

  // เปลี่ยนหน้า
  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  // หน้าก่อนหน้า
  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // หน้าถัดไป
  const goToNextPage = () => {
    if (currentPage < Math.ceil(sortedData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // ตั้งค้าจำนวนข้อมูลที่แสดง
  const setitemsPerPageInt = (e: any) => {
    let i = parseInt(e.toString());
    setitemsPerPage(i);
    handlePageChange(1);
    setCurrentPage(1);
  };

  // คำนวณหน้าเริ่มต้นและสิ้นสุดของเพจเนชันที่จะแสดง
  const startIndex = Math.max(currentPage - Math.floor(itemsPerPage / 2), 1);
  const endIndex = Math.min(
    startIndex + itemsPerPage - 1,
    Math.ceil(sortedData.length / itemsPerPage)
  );

  const rows = currentUsers.map((element: any, index) => (
    <tr key={element.agriculture_drone_id}>
      <td style={{ textAlign: "center" }} width={50}>
        {index + 1}
      </td>

      <td>{element.t_durable_idate}</td>
      <td>
        <AppModelShowImage path_image={element.t_durable_image} />
      </td>
      <td>{element.t_durable_code}</td>
      <td>{element.t_durable_name}</td>
      <td>{element.t_durable_count}</td>
      <td>{element.unit_name}</td>
      <td>{element.t_durable_money}</td>
      <td>{element.t_durable_all_money}</td>
      <td>{element.budget_name}</td>
      <td>{element.equipment_name}</td>
      <td>
        {element.agency_name} / {element.department_name}
      </td>
      <td>
        {element.t_durable_status_use == "1" ? (
          <Text c="green">{element.t_durable_countyear}</Text>
        ) : (
          <Text c="red">{element.t_durable_countyear}</Text>
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        {loadStatus(element.t_durable_status)}
      </td>
      <td style={{ textAlign: "center" }} width={200}>
        <Group spacing="xs" position="center">
          <AppModelAdd
            durable_id={element.t_durable_id}
            id={id}
            onLoadPage={logMessage}
          />
          <AppModelConfrimRemove
            id={element.t_durable_auto}
            path={`durable_articles/durable_articles/${element.t_durable_id}`}
            onLoadPage={logMessage}
          />
        </Group>
      </td>
    </tr>
  ));
  return (
    <>
      <LoadingOverlay visible={data.loading} />
      <Box>
        <Group position="right">
          <TextInput
            placeholder="Search by any field"
            icon={<IconSearch size="0.9rem" stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
          />
          <AppModelAdd durable_id="add" id={id} onLoadPage={logMessage} />
        </Group>
      </Box>
      <ScrollArea>
        <Table mt="md" fontSize="xs" highlightOnHover withBorder>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>#</th>
              <Th
                sorted={sortBy === "t_durable_idate"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_idate")}
              >
                วัน เดือน ปี
              </Th>
              <th>ภาพถ่ายครุภัณฑ์</th>
              <Th
                sorted={sortBy === "t_durable_code"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_code")}
              >
                หมายเลขครุภัณฑ์
              </Th>
              <Th
                sorted={sortBy === "t_durable_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_name")}
              >
                ชื่อครุภัณฑ์
              </Th>
              <Th
                sorted={sortBy === "t_durable_count"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_count")}
              >
                จำนวน
              </Th>
              <Th
                sorted={sortBy === "unit_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("unit_name")}
              >
                หน่วยนับ
              </Th>
              <Th
                sorted={sortBy === "t_durable_money"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_money")}
              >
                ราคาต่อหน่วย
              </Th>
              <Th
                sorted={sortBy === "t_durable_all_money"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_all_money")}
              >
                ราคารวม
              </Th>
              <Th
                sorted={sortBy === "budget_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("budget_name")}
              >
                งบประมาณ
              </Th>
              <Th
                sorted={sortBy === "equipment_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("equipment_name")}
              >
                ประเภทครุภัณฑ์
              </Th>
              <Th
                sorted={sortBy === "department_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("department_name")}
              >
                หน่วยงาน
              </Th>
              <Th
                sorted={sortBy === "t_durable_countyear"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_countyear")}
              >
                ค่าเสื่อม
              </Th>
              <Th
                sorted={sortBy === "t_durable_status_use"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("t_durable_status_use")}
              >
                สถานะ
              </Th>
              <th style={{ textAlign: "center" }}>จัดการ</th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={15}>
                  <Text weight={500} align="center">
                    ไม่พบข้อมูล
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <Group position="apart" mt="md">
        <Select
          style={{ width: "100px" }}
          width="50"
          size="xs"
          onChange={setitemsPerPageInt}
          value={itemsPerPage.toString()}
          data={[
            { value: "5", label: "5" },
            { value: "10", label: "10" },
            { value: "20", label: "20" },
            { value: "50", label: "50" },
            { value: `${rows.length}`, label: "ทั้งหมด" },
          ]}
        />
        <Pagination
          withEdges
          total={endIndex}
          size="sm"
          onNextPage={() => goToNextPage()}
          onPreviousPage={() => goToPrevPage()}
          onChange={handlePageChange}
          styles={(theme) => ({
            control: {
              "&[data-active]": {
                backgroundImage: theme.fn.gradient({
                  from: "red",
                  to: "yellow",
                }),
                border: 0,
              },
            },
          })}
        ></Pagination>
      </Group>
    </>
  );
}
