import { Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import React, { useState } from "react";

interface dataSearch {
  dataFilter: any;
  returnData: (e: any) => any;
}

export default function AppSearch({ dataFilter, returnData }: dataSearch) {
  const [dataList, setDataList] = useState(dataFilter);
  const [dataReturn, setDataReturn] = useState([]);

  const handleChange = (e: any) => {
    const searchText = e.target.value;
    const showSerach = dataFilter.filter((item: any, key: any) => {
      return Object.keys(item).some((key) =>
        item[key].toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setDataReturn(showSerach);
    returnData(showSerach);
  };
  return (
    <Input
      icon={<IconSearch size="1rem" />}
      placeholder="ค้นหา"
      onChange={handleChange}
    />
  );
}
